<template>
	<ul class="images-list pa-0 d-inline-flex flex-wrap">
		<li
			v-if="(!images || !images.length) && !is_comment"
			class="subtitle-2 font-weight-light grey--text"
		>
			Фотографии не прикреплены
		</li>
		<li class="relative" v-for="(img, i) of photos" :key="img.id">
			{{img.src}}
			<v-btn
				v-if="clearable"
				depressed
				x-small
				absolute
				right
				color="white primary--text"
				class="pa-0 close-btn rounded-0"
			>
				<v-icon x-small>mdi-close</v-icon>
			</v-btn>
			<v-tooltip bottom>
				<template v-slot:activator="{ on, attrs }">
					<v-img
						class="mr-2 rounded mb-2"
						max-height="80"
						max-width="140"
						v-bind="attrs"
						v-on="on"
						:lazy-src="img.image"
						:src="img.image"
						@click="toggleCarousel(i)"
					></v-img>
				</template>
				<span>Нажмите, чтобы увеличить</span>
			</v-tooltip>
		</li>
		<ImagesCarousel
			v-if="showCarousel"
			:images="photos"
			:initialIndex="imgIndex"
			@close-modal="showCarousel = false"
		/>
	</ul>
</template>

<script>
import ImagesCarousel from "@/components/app/ImagesCarousel";

export default {
	name: "ImagesList",

	components: { ImagesCarousel },

	props: {
		clearable: {
			type: Boolean,
			default: false,
		},

		images: {
			default: [],
			required: true,
		},
		is_comment: {
			type: Boolean,
			default: false,
		}
	},

	data() {
		return {
			imgIndex: 0,
			showCarousel: false,
			photos: []
		};
	},

	methods: {
		async loadImages() {
      for (const img of this.images) {
				const blob = await this.$api.ksk.load_file(img.system_id)

				const url = URL.createObjectURL(blob);
				this.photos.push({ id: img.system_id, image: url });
      }
    },
		toggleCarousel(index) {
			this.imgIndex = index;
			this.showCarousel = true;
		},
	},
	created() {
    this.loadImages();
  },
};
</script>

<style lang="scss" scoped>
.v-image {
	cursor: pointer !important;
}
.close-btn {
	z-index: 1;
	margin-right: -8px;
	min-width: 20px !important;
}
</style>
