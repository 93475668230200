<template>
	<v-dialog v-model="show" max-width="900" z-index="1000">
		<v-carousel v-model="index" height="auto" style="width: 100%;">
			<v-carousel-item
				v-for="img in images"
				:key="img.id"
				reverse-transition="fade-transition"
				transition="fade-transition"
				style="display: flex; justify-content: center; align-items: center;"
			>
				<img v-if="img.image" :src="img.image" style="max-height: 700px; max-width: 100%; height: auto; width: auto; margin: 0 auto; display: block;">
			</v-carousel-item>
		</v-carousel>
	</v-dialog>
</template>

<script>
export default {
	name: "ImagesCarousel",
	props: {
		images: {
			required: true,
			default: [],
		},
		initialIndex: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			show: true,
			index: this.initialIndex,
		};
	},
	watch: {
		show() {
			setTimeout(() => this.$emit("close-modal"), 100);
		},
	},
	methods: {
	},
};
</script>

<style scoped></style>
